import { CompanyKeys, ICompany } from "./companies.types";
import {
    Paginated,
    Sort,
    SortedPaginatedQueryParams,
} from "../sortedPaginated";
import { QueryOptions, usePaginatedQuery } from "react-query";
import { addCompanyIntercept, gatewayAuthErrorRedirect } from "../gateway";
import axiosBase, { AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { useMutationWrapper, useQueryWrapper } from "../reactQueryWrapper";

import groupBy from "../groupBy";

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api/`,
    withCredentials: true,
});
addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

type CompanyTypeBody = Paginated<ICompany>;

const COMPANY_QUERY = `companies`;
const QUERY_EXPIRE_TIME = 1000 * 60 * 60 * 12; //cache for 12 hours

interface Params extends SortedPaginatedQueryParams<ICompany> {
    search?: string;
}

const COMPANY_PARAMS: Params = {
    sorts: {},
    pageIndex: 0,
    pageSize: 20,
};

const sortedPaginated = (res?: AxiosResponse<ICompany[]>) =>
    ({
        total: Number(res?.headers["x-total-count"] || res?.data?.length || 0),
        data: res?.data,
    } as Paginated<ICompany>);

const prepareQuery = (params: Params) => {
    const param = {
        ...COMPANY_PARAMS,
        ...params,
        sorts: {
            ...COMPANY_PARAMS.sorts,
            ...params.sorts,
        },
    };
    const sorts = [...Object.entries(param.sorts), ["id", Sort.Ascending]]
        .filter((sort) => sort[1] != null)
        .map((sort) => "sort=" + sort.join(","))
        .join("&");

    return `page=${param.pageIndex}&size=${param.pageSize}&${sorts}${
        params.search != null && params.search.length > 0
            ? "&name.contains=" + encodeURIComponent(params.search)
            : ""
    }`;
};

const companiesMinRequest = () =>
    axiosBase
        .get<ICompany[]>("companies?status.in=ACTIVE&page=0&size=100000", {
            baseURL: axios.defaults.baseURL,
        })
        .then((x) => ({
            entity: x.data,
            map: groupBy(x.data, "id"),
        }));
export const useCompaniesMin = () =>
    usePaginatedQuery([COMPANY_QUERY + "_min"], companiesMinRequest, {
        retry: 1,
        staleTime: QUERY_EXPIRE_TIME,
    });
// &companyTypeString.in=" +encodeURIComponent("Active Marketplace Buyer") +"
const quotesCompaniesRequest = () =>
    axiosBase
        .get<ICompany[]>(
            "companies?status.in=ACTIVE&consignmentAgreementType.in=GSMS&consignmentAgreementType.in=ACTIVE&page=0&size=100000",
            {
                baseURL: axios.defaults.baseURL,
            }
        )
        .then((x) => ({
            entity: x.data,
            map: groupBy(x.data, "id"),
        }));
export const useQuotesCompanies = () =>
    usePaginatedQuery(["quotes_companies_min"], quotesCompaniesRequest, {
        retry: 1,
        staleTime: QUERY_EXPIRE_TIME,
    });

export const useCompanies = (
    params = COMPANY_PARAMS,
    options?: QueryOptions<CompanyTypeBody>
) => {
    const query = useMemo(() => prepareQuery(params), [params]);
    const request = useCallback(
        () => axios.get<ICompany[]>(`companies?${query}`).then(sortedPaginated),
        [query]
    );
    return useQueryWrapper<CompanyTypeBody, any>(
        [COMPANY_QUERY, { query }],
        request,
        {
            ...options,
            retry: 1,
            staleTime: QUERY_EXPIRE_TIME,
        }
    );
};

export const useCompany = () => {
    const request = useCallback(
        () => axios.get<ICompany>(`companies/getco`).then((res) => res.data),
        []
    );
    return useQueryWrapper<ICompany, any>([COMPANY_QUERY, {}], request, {
        retry: 1,
        staleTime: QUERY_EXPIRE_TIME,
    });
};

const deleteItemRequest = (item: { id: number }) =>
    axios.delete(`companies/${item.id}`);
export const useDeleteItem = () => {
    return useMutationWrapper(deleteItemRequest, {
        refetchQueries: [COMPANY_QUERY],
    });
};

export const useDeleteItems = () => {
    const request = useCallback(
        (rows: number[]) =>
            axiosBase.all(rows.map((id) => deleteItemRequest({ id }))),
        []
    );
    return useMutationWrapper(request, { refetchQueries: [COMPANY_QUERY] });
};

const COMPANY_FIELDS_QUERY = "itemCompanyFields";
const COMPANY_FIELDS_EXPIRE = 1000;
const fieldEndpoints: [CompanyKeys, string][] = [
    ["type", "company-types"],
    ["country", "countries"],
    ["currency", "currency"],
    ["companyGroups", "company-group"],
    [
        "interOpcoSellingCompanies",
        "companies?status.in=ACTIVE&page=0&size=100000",
    ],
    ["internalGSMSCompanies", "companies?status.in=ACTIVE&page=0&size=100000"],
    ["emailDistributionLists", "contacts"],
    ["assignees", "users"],
];
const fieldsRequest = () =>
    axiosBase.all<[CompanyKeys, any]>(
        fieldEndpoints.map(([field, endpoint]) =>
            axios.get(endpoint).then((x) => [field, x.data])
        )
    );
export const useCompanyNestedFields = (editing: boolean) =>
    useQueryWrapper(editing ? COMPANY_FIELDS_QUERY : null, fieldsRequest, {
        staleTime: COMPANY_FIELDS_EXPIRE,
    });

const updateRequest = ({ data, create }: { data: ICompany; create: boolean }) =>
    axios.request({
        url: create ? "/companies" : "/companies/" + data.id,
        method: create ? "POST" : "PUT",
        data,
    });
export const useUpdateCompany = () =>
    useMutationWrapper(updateRequest, { refetchQueries: [COMPANY_QUERY] });

export default axios;
