import { addCompanyIntercept, gatewayAuthErrorRedirect } from "../gateway";
import axiosBase, { AxiosResponse } from "axios";
import { useCallback, useMemo } from "react";
import { useMutationWrapper, useQueryWrapper } from "../reactQueryWrapper";

import { IMenuHelpVideo } from "./menuHelpVideos.types";
import { Paginated } from "../sortedPaginated";
import { useQuery } from "react-query";

const QUERY_EXPIRE_TIME = 1000 * 60 * 60 * 12;

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api`,
    withCredentials: true,
    maxRedirects: 0,
});
addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

const MENU_HELP_VIDEOS_QUERY = `/menu-help-videos`;
const MENU_HELP_VIDEOS_LINK_QUERY = "/menu-help-videos-link";

interface Params {
    search?: string;
    viewOnly?: boolean;
    status?: string;
}

const sortedPaginated = (res?: AxiosResponse<IMenuHelpVideo[]>) =>
    ({
        total: Number(res?.headers["x-total-count"] || res?.data?.length || 0),
        data: res?.data,
    } as Paginated<IMenuHelpVideo>);

const prepareQuery = (params: Params) => {
    const { search } = params;
    const queryParams: Record<string, any> = {};
    queryParams["keyword.contains"] = search ? encodeURI(search) : undefined;
    return queryParams;
};

export const useMenuHelpVideos = (params: Params = {}) => {
    const query = useMemo(() => prepareQuery(params), [params]);
    const request = useCallback(
        () =>
            axios
                .get<IMenuHelpVideo[]>(MENU_HELP_VIDEOS_QUERY, {
                    params: query,
                })
                .then(sortedPaginated),
        [query]
    );
    return useQueryWrapper<Paginated<IMenuHelpVideo>, any>(
        [MENU_HELP_VIDEOS_QUERY, { query }],
        request,
        {
            retry: 1,
            staleTime: QUERY_EXPIRE_TIME,
        }
    );
};

const deleteMenuHelpVideosRequest = (menuHelpVideos: { id: number }) =>
    axios.delete(`${MENU_HELP_VIDEOS_QUERY}/${menuHelpVideos.id}`);

export const useDeleteMenuHelpVideos = () => {
    const request = useCallback(
        (rows: number[]) =>
            axiosBase.all(
                rows.map((id) => deleteMenuHelpVideosRequest({ id }))
            ),
        []
    );
    return useMutationWrapper(request, {
        refetchQueries: [MENU_HELP_VIDEOS_QUERY],
    });
};

const updateMenuHelpVideosRequest = ({
    data,
    create,
}: {
    data: IMenuHelpVideo;
    create: boolean;
}) =>
    axios.request({
        url: `${MENU_HELP_VIDEOS_QUERY}${create ? "" : "/" + data.id}`,
        method: create ? "POST" : "PUT",
        data,
    });
export const useUpdateMenuHelpVideos = () => {
    return useMutationWrapper(updateMenuHelpVideosRequest, {
        refetchQueries: [MENU_HELP_VIDEOS_QUERY],
    });
};

const menuHelpVideosLink = async () => {
    try {
        const videosLink = await axios.get<IMenuHelpVideo[]>(
            `${MENU_HELP_VIDEOS_LINK_QUERY}`
        );
        return videosLink.data;
    } catch (error) {
        return {} as IMenuHelpVideo[];
    }
};

export const useMenuHelpVideosLink = () =>
    useQuery([MENU_HELP_VIDEOS_LINK_QUERY], menuHelpVideosLink, {
        retry: 0,
        staleTime: QUERY_EXPIRE_TIME,
    });

export default axios;
