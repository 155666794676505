import { addCompanyIntercept, gatewayAuthErrorRedirect } from "../gateway";
import axiosBase from "axios";
import { useQuery } from "react-query";
import { useQueryWrapper } from "../reactQueryWrapper";

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifeinventoryservice/api`,
    withCredentials: true,
    maxRedirects: 0,
});
addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

const FIELDS_QUERY = "npsHelpdeskQuery";
const FIELDS_EXPIRE = 1000 * 60 * 60 * 12; //cache for 12 hours

const authRequest = () =>
    axios.post("nps-support-auth").catch(function (error) {
        // toast.error(`NPS Helpdesk Auth is failing: ${error}`, {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 3000,
        //     className: "mt-5 mr-2",
        // });
        console.error("NPS Helpdesk Auth is failing: ", error);
    });

export const useNpsHelpdeskAuth = () =>
    useQueryWrapper(FIELDS_QUERY, authRequest, {
        staleTime: FIELDS_EXPIRE,
    });

const formBuilderRequest = (_: any, token: string) =>
    axios.get(`nps-form-builder/${token}`);

export const useNpsFormBuilder = (token: string) =>
    useQuery(token ? [FIELDS_QUERY, token] : null, formBuilderRequest, {
        staleTime: FIELDS_EXPIRE,
    });

export default axios;
